import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Tabs, Tab, Typography, Box } from '@material-ui/core'
import sharedStyles from '../styles/global'

import withLocation from './withLocation'
import { getQueryStringParams, determineActiveTab, determineInitialTab } from '../services/utilityService'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  wrapper: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}))

const participateSubMenus = ({ selected, changed, location }) => {
  const theme = useTheme()
  const css = useStyles(theme)

  function handleTabChange(evt, newTabIndex) {
    changed(evt, newTabIndex)
    setActiveTab(newTabIndex)
    const newTabParamValue = tabNames[newTabIndex].toLowerCase()
    if (typeof window !== 'undefined') {
      const newURL = `${window.location.origin}${window.location.pathname}?tab=${encodeURIComponent(newTabParamValue)}`
      window.history.pushState({ path: newURL, tabIndex: newTabIndex }, null, newURL)
    }
    setTabParam(newTabParamValue)
  }

  const tabNames = ['INTERNS', 'EDUCATORS', 'CONTACT US']

  const [tabParam, setTabParam] = useState('')
  const queryParams = getQueryStringParams()
  const [activeTab, setActiveTab] = useState(() => determineInitialTab(location.href, tabNames))

  useEffect(() => {
    determineActiveTab(queryParams.tab, tabNames, setTabParam, setActiveTab)
  }, [tabParam])

  return (
    <div className={css.wrapper}>
      <Tabs
        aria-label="scrollable auto tabs example"
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value={selected}
        variant="scrollable"
        onChange={handleTabChange}
      >
        {tabNames.map((tabName, idx) => {
          const tabStyles = [css.tabControl, css.sansProSemiBold]
          if (idx !== selected) {
            tabStyles.push(css.nonActiveTab)
          }
          const tabClasses = tabStyles.join(' ')
          return <Tab key={`${tabName}-tab-${idx}`} label={tabName} {...a11yProps(idx)} className={tabClasses} />
        })}
      </Tabs>
    </div>
  )
}
export default withLocation(participateSubMenus)
