/* eslint-disable no-unused-vars */
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import sharedStyles from '../styles/global'
import Modal from './modal'
import ContactUs from './contactUs'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  wrapper: {
    width: '100%',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    '& a': {
      textDecoration: 'none',
      color: '#1d3787',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  divider: {
    color: 'rgba(29,55,135, 0.25)',
    borderColor: 'rgba(29,55,135, 0.25)',
    width: '100%',
    marginBottom: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  leftHalf: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifySelf: 'end',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  rightHalf: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifySelf: 'start',
    },
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
    justifyContent: 'center',
  },
  nasaLogo: {
    width: '75px',
    height: '75px',
    marginRight: theme.spacing(2),
  },
  goddardLogo: {
    position: 'relative',
    top: theme.spacing(2),
  },
  emailSection: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  noWordWrap: {
    whiteSpace: 'nowrap',
  },
  socialMedia: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  stayConnected: {
    marginBottom: theme.spacing(2), // overrides the global h3 margin bottom
  },
  socialIcons: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(2),
      gridTemplateColumns: '1fr',
    },
  },
  socialLink: {
    marginBottom: theme.spacing(1),
    minWidth: theme.spacing(7),
    GridColumnAlign: 'center',
    display: 'block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(0),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  socialIconLabel: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    top: '-0.3rem',
  },
  NearSpaceNetTW: {
    GridRow: '1',
    GridColumn: '1',
    [theme.breakpoints.down('xs')]: {
      gridRow: '1',
      gridColumn: '1',
    },
  },
  NASANearSpaceNetFB: {
    GridRow: '1',
    GridColumn: '2',
    [theme.breakpoints.down('xs')]: {
      gridRow: '1',
      gridColumn: '3',
    },
  },
  LaserCommTW: {
    GridRow: '1',
    GridColumn: '3',
    [theme.breakpoints.down('xs')]: {
      gridRow: '2',
      gridColumn: '2',
    },
  },
  policy: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1.5),
    },
  },
  socialLogo: {
    height: '20px',
  },
}))

const Footer = ({}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const data = useStaticQuery(graphql`
    query {
      nasaLogo: file(relativePath: { eq: "nasa-logo.svg" }) {
        name
        publicURL
      }
      goddard: file(relativePath: { eq: "gsfc-logo.png" }) {
        childImageSharp {
          fixed(height: 60) {
            src
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        name
        publicURL
      }
      facebook: file(relativePath: { eq: "facebook.svg" }) {
        name
        publicURL
      }
    }
  `)

  const goto = function (event, name) {
    // eslint-disable-next-line no-undef
    navigate(`/${name}`)
  }

  // this is the code for the popup
  const [open, setOpen] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  // end of code for popup

  return (
    <footer className={classes.wrapper}>
      <hr className={classes.divider} />
      <div className={classes.container}>
        <div className={classes.leftHalf}>
          <div className={classes.logoSection}>
            <img alt="NASA Logo" className={classes.nasaLogo} src={data.nasaLogo.publicURL} />
            <Img className={classes.goddardLogo} fixed={data.goddard.childImageSharp.fixed} />
          </div>
          <div className={[classes.emailSection, classes.pt18, classes.noWordWrap].join(' ')}>
            <div>
              <strong>NASA OFFICIAL:</strong> <a href="mailto:patrick.a.hill@nasa.gov">PATRICK HILL</a>
            </div>
            <div>
              <strong>WEB CURATOR:</strong> <a href="mailto:kendall.t.murphy@nasa.gov">KENDALL MURPHY</a>
            </div>
          </div>
        </div>
        <div className={classes.rightHalf}>
          <div className={classes.socialMedia}>
            <div className={classes.noWordWrap}>
              <h3 className={classes.stayConnected}>STAY CONNECTED WITH US</h3>
            </div>
            <div className={classes.socialIcons}>
              <a
                className={[classes.socialLink, classes.lcrdTWT].join(' ')}
                href="https://twitter.com/NASASCaN"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="NASASCaN Twitter" className={classes.socialLogo} src={data.twitter.publicURL} />
                <span className={[classes.socialIconLabel, classes.pt18].join(' ')}>NASASCaN</span>
              </a>
              <a
                className={[classes.socialLink, classes.escFB].join(' ')}
                href="https://www.facebook.com/NASASCaN/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="Facebook To NASASCaN " className={classes.socialLogo} src={data.facebook.publicURL} />
                <span className={[classes.socialIconLabel, classes.pt18].join(' ')}>NASASCaN</span>
              </a>
            </div>
          </div>
          <div className={classes.policy}>
            <a className={[classes.noWordWrap, classes.pt18].join(' ')} href="/otherServices?tab=contact%20us">
              CONTACT US
            </a>
            <a className={[classes.noWordWrap, classes.pt18].join(' ')} href="https://www.nasa.gov/privacy/">
              PRIVACY POLICY
            </a>
            <a className={[classes.noWordWrap, classes.pt18].join(' ')} href="/sitemap" rel="noopener noreferrer">
              SITEMAP
            </a>
            <a
              className={[classes.noWordWrap, classes.pt18].join(' ')}
              href="/static-files/450_ORG_CHART.pdf"
              target="_blank"
            >
              ORG CHART
            </a>
            <a
              className={[classes.noWordWrap, classes.pt18].join(' ')}
              href="https://www.nasa.gov/accessibility"
              rel="noreferrer"
              target="_blank"
            >
              ACCESSIBILITY
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
